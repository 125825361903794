import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { useSwipeable } from 'react-swipeable';

function ImageModal({ show, handleClose, currentImage, onPrev, onNext, showNavigation }) {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      centered 
      fullscreen
      style={{ 
        zIndex: 9999
      }}
      backdropClassName="modal-backdrop-dark"
    >
      <style>
        {`
          .modal-backdrop-dark {
            background-color: rgba(0, 0, 0, 0.8) !important;
            backdrop-filter: blur(8px);
          }
          .modal-content {
            background: rgba(0, 0, 0, 0.85);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.18);
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            touch-action: pan-y pinch-zoom;
          }
          .modal-dialog {
            margin: 0;
            max-width: 100% !important;
          }
          .modal img {
            max-height: 90vh;
            max-width: 90vw;
            object-fit: contain;
          }
        `}
      </style>
      <div {...swipeHandlers}>
        <Modal.Header closeButton className="border-0 btn-close-white">
        </Modal.Header>
        <Modal.Body className="p-0">
          <div 
            className="position-relative d-flex align-items-center justify-content-center"
            style={{ 
              height: '80vh',
              backgroundColor: 'transparent'
            }}
          >
            {showNavigation && (
              <>
                <button
                  className="btn btn-dark position-absolute top-50 start-0 translate-middle-y ms-2"
                  onClick={onPrev}
                  style={{ zIndex: 1 }}
                >
                  &lt;
                </button>
                <button
                  className="btn btn-dark position-absolute top-50 end-0 translate-middle-y me-2"
                  onClick={onNext}
                  style={{ zIndex: 1 }}
                >
                  &gt;
                </button>
              </>
            )}
            <Image 
              src={currentImage} 
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                padding: '20px'
              }} 
            />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default ImageModal;
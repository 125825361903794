import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import AppHeader from './components/header';
import AppHero from './components/hero';
import AppAbout from './components/about';
import Founder from './components/founder';
import AppServices from './components/services';
import AppWorks from './components/works';
// import AppTeams from './components/teams';
import AppTestimonials from './components/testimonials';
import AppPricing from './components/pricing';
// import AppBlog from './components/blog';
import SponsorsPage from './components/sponsors';
import AppContact from './components/contact';
import AppFooter from './components/footer';

function App() {
  return (
    <div className="App">
      <header id='header'>
        <AppHeader />
      </header>
      <main>
        <AppHero />
        <AppPricing />
        <AppAbout />
        <Founder />
        <AppServices />
        <AppWorks />
        {/* <AppTeams /> */}
        <AppTestimonials />
        {/* <AppBlog /> */}
        <SponsorsPage/>
        <AppContact />
      </main>
      <footer id="footer">
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;

import Carousel from "react-bootstrap/Carousel";

var heroData = [
  {
    id: 1,
    image: require("../assets/images/img-hero1.jpeg"),
    title: "Empowering East African Youth",
    description:
      "Join us in celebrating the achievements of young leaders, entrepreneurs, and change-makers across East Africa. Participate in our awards program and make a difference!",
    link: "https://forms.gle/ptM1weGCGyrRS2TG9",
  },
  {
    id: 2,
    image: require("../assets/images/img-hero2.jpeg"),
    title: "Recognize Outstanding Achievements",
    description:
      "Be part of our mission to honor the exceptional accomplishments of East African youth. Your vote can help shine a light on their incredible work.",
    link: "https://forms.gle/ptM1weGCGyrRS2TG9",
  },
  {
    id: 3,
    image: require("../assets/images/img-hero3.jpeg"),
    title: "Celebrate Youth Leadership",
    description:
      "Join our initiative to celebrate and empower young leaders across East Africa. Your involvement can make a significant impact in recognizing their efforts.",
    link: "https://forms.gle/ptM1weGCGyrRS2TG9",
  },
  {
    id: 4,
    image: require("../assets/images/img-hero4.jpeg"),
    title: "Support Young Innovators",
    description:
      "Help us support and uplift the next generation of East African innovators. Participate in our awards program and celebrate their contributions to our community.",
    link: "https://forms.gle/ptM1weGCGyrRS2TG9",
  },
];

function AppHero() {
  return (
    <section id="home" className="hero-block">
      <Carousel>
        {heroData.map((hero) => {
          return (
            <Carousel.Item key={hero.id}>
              <img
                className="d-block w-100"
                src={hero.image}
                alt={"slide " + hero.id}
              />
              <Carousel.Caption>
                <h2>{hero.title}</h2>
                <p>{hero.description}</p>
                {/* <a className="btn btn-primary" href={hero.link}>
                  Apply Now <i className="fas fa-chevron-right"></i>
                </a> */}
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
}

export default AppHero;

import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Pagination from "react-bootstrap/Pagination";
import ImageModal from './ImageModal';

// Update image imports as needed
import img2 from "../assets/images/img2.jpeg";
import img3 from "../assets/images/img3.jpeg";
import img4 from "../assets/images/img4.jpeg";
import img5 from "../assets/images/img5.jpeg";
import img6 from "../assets/images/img6.jpeg";
import img7 from "../assets/images/img7.jpeg";
import img8 from "../assets/images/img8.jpeg";
import img9 from "../assets/images/img9.jpeg";
import img10 from "../assets/images/img10.jpeg";
import img11 from "../assets/images/img11.jpeg";
import img12 from "../assets/images/img12.jpeg";
import img13 from "../assets/images/img13.jpeg";
import img14 from "../assets/images/img14.jpeg";
import img15 from "../assets/images/img15.jpeg";
import img16 from "../assets/images/img16.jpeg";
import img17 from "../assets/images/img17.jpeg";
import img18 from "../assets/images/img18.jpeg";
import img19 from "../assets/images/img19.jpeg";
import img20 from "../assets/images/img20.jpeg";
import img21 from "../assets/images/img21.jpeg";
import img22 from "../assets/images/img22.jpeg";
import img23 from "../assets/images/img23.jpeg";
import img24 from "../assets/images/img24.jpeg";
import img25 from "../assets/images/img25.jpeg";
import img26 from "../assets/images/img26.jpeg";
import img27 from "../assets/images/img27.jpeg";
import img28 from "../assets/images/img28.jpeg";
import img29 from "../assets/images/img29.jpeg";
import img30 from "../assets/images/img30.jpeg";
import img31 from "../assets/images/img31.jpeg";
import img32 from "../assets/images/img32.jpeg";
import img33 from "../assets/images/img33.jpeg";
import img34 from "../assets/images/img34.jpeg";
import img35 from "../assets/images/img35.jpeg";
import img36 from "../assets/images/img36.jpeg";
import img37 from "../assets/images/img37.jpeg";
import img38 from "../assets/images/img38.jpeg";
import img39 from "../assets/images/img39.jpeg";
import img40 from "../assets/images/img40.jpeg";
import img41 from "../assets/images/img41.jpeg";
import img42 from "../assets/images/img42.jpeg";
import img43 from "../assets/images/img43.jpeg";
import img44 from "../assets/images/img44.jpeg";
import img45 from "../assets/images/img45.jpeg";
import img46 from "../assets/images/img46.jpeg";
import img47 from "../assets/images/img47.jpeg";
import img48 from "../assets/images/img48.jpeg";
import img49 from "../assets/images/img49.jpeg";
import img50 from "../assets/images/img50.jpeg";
import img51 from "../assets/images/img51.jpeg";
import img52 from "../assets/images/img52.jpeg";
import img53 from "../assets/images/img53.jpeg";
import img54 from "../assets/images/img54.jpeg";
import img55 from "../assets/images/img55.jpeg";
import img56 from "../assets/images/img56.jpeg";
import img57 from "../assets/images/img57.jpeg";
import img58 from "../assets/images/img58.jpeg";
import img59 from "../assets/images/img59.jpeg";
import img60 from "../assets/images/img60.jpeg";
import img61 from "../assets/images/img61.jpeg";
import img62 from "../assets/images/img62.jpeg";
import img63 from "../assets/images/img63.jpeg";
import img64 from "../assets/images/img64.jpeg";
import img65 from "../assets/images/img65.jpeg";
import img66 from "../assets/images/img66.jpeg";
import img67 from "../assets/images/img67.jpeg";
import img68 from "../assets/images/img68.jpeg";
import img69 from "../assets/images/img69.jpeg";
import img70 from "../assets/images/img70.jpeg";
import img71 from "../assets/images/img71.jpeg";
import img72 from "../assets/images/img72.jpeg";
import img73 from "../assets/images/img73.jpeg";
import img74 from "../assets/images/img74.jpeg";
import img75 from "../assets/images/img75.jpeg";
import img76 from "../assets/images/img76.jpeg";
import img77 from "../assets/images/img77.jpeg";
import img78 from "../assets/images/img78.jpeg";
import img79 from "../assets/images/img79.jpeg";
import img80 from "../assets/images/img80.jpeg";
import img81 from "../assets/images/img81.jpeg";
import img82 from "../assets/images/img82.jpeg";
import img83 from "../assets/images/img83.jpeg";
import img84 from "../assets/images/img84.jpeg";
import img85 from "../assets/images/img85.jpeg";
import img86 from "../assets/images/img86.jpeg";
import img87 from "../assets/images/img87.jpeg";
import img88 from "../assets/images/img88.jpeg";
import img89 from "../assets/images/img89.jpeg";
import img90 from "../assets/images/img90.jpeg";
import img91 from "../assets/images/img91.jpeg";
import img92 from "../assets/images/img92.jpeg";
import img93 from "../assets/images/img93.jpeg";
import img94 from "../assets/images/img94.jpeg";
import img95 from "../assets/images/img95.jpeg";
import img96 from "../assets/images/img96.jpeg";
import img97 from "../assets/images/img97.jpeg";
import img98 from "../assets/images/img98.jpeg";
import img99 from "../assets/images/img99.jpeg";
import img100 from "../assets/images/img100.jpeg";
import img101 from "../assets/images/img101.jpeg";
import img102 from "../assets/images/img102.jpeg";
import img103 from "../assets/images/img103.jpeg";
import img104 from "../assets/images/img104.jpeg";
import img105 from "../assets/images/img105.jpeg";
import img106 from "../assets/images/img106.jpeg";
import img107 from "../assets/images/img107.jpeg";
import img108 from "../assets/images/img108.jpeg";
import img109 from "../assets/images/img109.jpeg";
import img110 from "../assets/images/img110.jpeg";
import img111 from "../assets/images/img111.jpeg";
import img112 from "../assets/images/img112.jpeg";
import img113 from "../assets/images/img113.jpeg";
import img114 from "../assets/images/img114.jpeg";
import img115 from "../assets/images/img115.jpeg";
import img116 from "../assets/images/img116.jpeg";
import img117 from "../assets/images/img117.jpeg";
import img118 from "../assets/images/img118.jpeg";
import img119 from "../assets/images/img119.jpeg";
import img120 from "../assets/images/img120.jpeg";
import img121 from "../assets/images/img121.jpeg";
import img122 from "../assets/images/img122.jpeg";
import img123 from "../assets/images/img123.jpeg";
import img124 from "../assets/images/img124.jpeg";
import img125 from "../assets/images/img125.jpeg";
import img126 from "../assets/images/img126.jpeg";
import img127 from "../assets/images/img127.jpeg";
import img128 from "../assets/images/img128.jpeg";
import img129 from "../assets/images/img129.jpeg";
import img130 from "../assets/images/img130.jpeg";
import img131 from "../assets/images/img131.jpeg";
import img132 from "../assets/images/img132.jpeg";
import img133 from "../assets/images/img133.jpeg";

const worksData = [
  {
    id: 1,
    // link: "https://www.google.com",
    image: img2,
    // title: "Lonely Path",
    // subtitle: "Web Design",
  },
  {
    id: 2,
    // link: "https://www.google.com",
    image: img3,
    // title: "Photographer Girl",
    // subtitle: "Branding",
  },
  {
    id: 3,
    // link: "https://www.google.com",
    image: img4,
    // title: "The Difference",
    // subtitle: "Web Design",
  },
  {
    id: 4,
    // link: "https://www.google.com",
    image: img5,
    // title: "Nature Patterns",
    // subtitle: "Branding",
  },
  {
    id: 5,
    // link: "https://www.google.com",
    image: img6,
    // title: "The Difference",
    // subtitle: "Photography",
  },
  {
    id: 6,
    // link: "https://www.google.com",
    image: img7,
    // title: "Winter Sonata",
    // subtitle: "Web Design",
  },
  {
    id: 7,
    // link: "https://www.google.com",
    image: img8,
    // title: "Lonely Path",
    // subtitle: "Branding",
  },
  {
    id: 8,
    // link: "https://www.google.com",
    image: img9,
    // title: "Appreciation",
    // subtitle: "Photography",
  },
  {
    id: 9,
    // link: "https://www.google.com",
    image: img2,
    // title: "Happy Days",
    // subtitle: "Web Design",
  },
  {
    id: 10,
    image: img2,
  },
  {
    id: 10,
    image: img10,
  },
  {
    id: 11,
    image: img11,
  },
  {
    id: 12,
    image: img12,
  },
  {
    id: 13,
    image: img13,
  },
  {
    id: 14,
    image: img14,
  },
  {
    id: 15,
    image: img15,
  },
  {
    id: 16,
    image: img16,
  },
  {
    id: 17,
    image: img17,
  },
  {
    id: 18,
    image: img18,
  },
  {
    id: 19,
    image: img19,
  },
  {
    id: 20,
    image: img20,
  },
  {
    id: 21,
    image: img21,
  },
  {
    id: 22,
    image: img22,
  },
  {
    id: 23,
    image: img23,
  },
  {
    id: 24,
    image: img24,
  },
  {
    id: 25,
    image: img25,
  },
  {
    id: 26,
    image: img26,
  },
  {
    id: 27,
    image: img27,
  },
  {
    id: 28,
    image: img28,
  },
  {
    id: 29,
    image: img29,
  },
  {
    id: 30,
    image: img30,
  },
  {
    id: 31,
    image: img31,
  },
  {
    id: 32,
    image: img32,
  },
  {
    id: 33,
    image: img33,
  },
  {
    id: 34,
    image: img34,
  },
  {
    id: 35,
    image: img35,
  },
  {
    id: 36,
    image: img36,
  },
  {
    id: 37,
    image: img37,
  },
  {
    id: 38,
    image: img38,
  },
  {
    id: 39,
    image: img39,
  },
  {
    id: 40,
    image: img40,
  },
  {
    id: 41,
    image: img41,
  },
  {
    id: 42,
    image: img42,
  },
  {
    id: 43,
    image: img43,
  },
  {
    id: 44,
    image: img44,
  },
  {
    id: 45,
    image: img45,
  },
  {
    id: 46,
    image: img46,
  },
  {
    id: 47,
    image: img47,
  },
  {
    id: 48,
    image: img48,
  },
  {
    id: 49,
    image: img49,
  },
  {
    id: 50,
    image: img50,
  },
  {
    id: 51,
    image: img51,
  },
  {
    id: 52,
    image: img52,
  },
  {
    id: 53,
    image: img53,
  },
  {
    id: 54,
    image: img54,
  },
  {
    id: 55,
    image: img55,
  },
  {
    id: 56,
    image: img56,
  },
  {
    id: 57,
    image: img57,
  },
  {
    id: 58,
    image: img58,
  },
  {
    id: 59,
    image: img59,
  },
  {
    id: 60,
    image: img60,
  },
  {
    id: 61,
    image: img61,
  },
  {
    id: 62,
    image: img62,
  },
  {
    id: 63,
    image: img63,
  },
  {
    id: 64,
    image: img64,
  },
  {
    id: 65,
    image: img65,
  },
  {
    id: 66,
    image: img66,
  },
  {
    id: 67,
    image: img67,
  },
  {
    id: 68,
    image: img68,
  },
  {
    id: 69,
    image: img69,
  },
  {
    id: 70,
    image: img70,
  },
  {
    id: 71,
    image: img71,
  },
  {
    id: 72,
    image: img72,
  },
  {
    id: 73,
    image: img73,
  },
  {
    id: 74,
    image: img74,
  },
  {
    id: 75,
    image: img75,
  },
  {
    id: 76,
    image: img76,
  },
  {
    id: 77,
    image: img77,
  },
  {
    id: 78,
    image: img78,
  },
  {
    id: 79,
    image: img79,
  },
  {
    id: 80,
    image: img80,
  },
  {
    id: 81,
    image: img81,
  },
  {
    id: 82,
    image: img82,
  },
  {
    id: 83,
    image: img83,
  },
  {
    id: 84,
    image: img84,
  },
  {
    id: 85,
    image: img85,
  },
  {
    id: 86,
    image: img86,
  },
  {
    id: 87,
    image: img87,
  },
  {
    id: 88,
    image: img88,
  },
  {
    id: 89,
    image: img89,
  },
  {
    id: 90,
    image: img90,
  },
  {
    id: 91,
    image: img91,
  },
  {
    id: 92,
    image: img92,
  },
  {
    id: 93,
    image: img93,
  },
  {
    id: 94,
    image: img94,
  },
  {
    id: 95,
    image: img95,
  },
  {
    id: 96,
    image: img96,
  },
  {
    id: 97,
    image: img97,
  },
  {
    id: 98,
    image: img98,
  },
  {
    id: 99,
    image: img99,
  },
  {
    id: 100,
    image: img100,
  },
  {
    id: 101,
    image: img101,
  },
  {
    id: 102,
    image: img102,
  },
  {
    id: 103,
    image: img103,
  },
  {
    id: 104,
    image: img104,
  },
  {
    id: 105,
    image: img105,
  },
  {
    id: 106,
    image: img106,
  },
  {
    id: 107,
    image: img107,
  },
  {
    id: 108,
    image: img108,
  },
  {
    id: 109,
    image: img109,
  },
  {
    id: 110,
    image: img110,
  },
  {
    id: 111,
    image: img111,
  },
  {
    id: 112,
    image: img112,
  },
  {
    id: 113,
    image: img113,
  },
  {
    id: 114,
    image: img114,
  },
  {
    id: 115,
    image: img115,
  },
  {
    id: 116,
    image: img116,
  },
  {
    id: 117,
    image: img117,
  },
  {
    id: 118,
    image: img118,
  },
  {
    id: 119,
    image: img119,
  },
  {
    id: 120,
    image: img120,
  },
  {
    id: 121,
    image: img121,
  },
  {
    id: 122,
    image: img122,
  },
  {
    id: 123,
    image: img123,
  },
  {
    id: 124,
    image: img124,
  },
  {
    id: 125,
    image: img125,
  },
  {
    id: 126,
    image: img126,
  },
  {
    id: 127,
    image: img127,
  },
  {
    id: 128,
    image: img128,
  },
  {
    id: 129,
    image: img129,
  },
  {
    id: 130,
    image: img130,
  },
  {
    id: 131,
    image: img131,
  },
  {
    id: 132,
    image: img132,
  },
  {
    id: 133,
    image: img133,
  },
];

function AppWorks() {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 6; // Number of items per page
  const maxPageNumbersToShow = 5; // Maximum number of page numbers to display

  // Replace shuffledWorksData with originalWorksData for modal
  const [originalWorksData] = useState([...worksData]); // Store original order
  const [shuffledWorksData, setShuffledWorksData] = useState([]); // For display grid only
  
  useEffect(() => {
    // Only shuffle for display grid, not modal
    const shuffled = [...worksData].sort(() => Math.random() - 0.5);
    setShuffledWorksData(shuffled);
  }, []);

  const handlePageChange = (number) => {
    setActivePage(number);
  };

  const startIndex = (activePage - 1) * itemsPerPage;
  const selectedWorks = shuffledWorksData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages = Math.ceil(worksData.length / itemsPerPage);
  const paginationItems = [];

  const startPage = Math.max(
    1,
    activePage - Math.floor(maxPageNumbersToShow / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

  if (activePage > 1) {
    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => handlePageChange(activePage - 1)}
      />
    );
  }

  for (let number = startPage; number <= endPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  if (activePage < totalPages) {
    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() => handlePageChange(activePage + 1)}
      />
    );
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    // Find the actual image in original data
    const clickedImage = shuffledWorksData[index];
    const originalIndex = originalWorksData.findIndex(img => img.id === clickedImage.id);
    setSelectedImageIndex(originalIndex);
    setShowModal(true);
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prev) => 
      prev === 0 ? originalWorksData.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prev) => 
      prev === originalWorksData.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <>
      <section id="works" className="block works-block">
        <Container fluid>
          <div className="title-holder">
            <h2>Gallery</h2>

            <div className="subtitle mb-4">External Links</div>
            {/* this section is for links to external gallery for the year  */}
            <div className="gallery-links mb-4">
              <Row className="justify-content-center">
                <Col xs="auto">
                  <button
                    className="gallery-btn"
                    onClick={() =>
                      window.open(
                        "https://photos.app.goo.gl/euNonuYLCJTyHYJVA",
                        "_blank"
                      )
                    }
                  >
                    2024 Gallery
                  </button>
                </Col>
              </Row>
            </div>
            <div className="subtitle">Past Events</div>
          </div>
          <Row className="portfoliolist g-4">
            {selectedWorks.map((works) => {
              return (
                <Col sm={4} key={works.id} className="portfolio-item">
                  <div className="portfolio-wrapper rounded shadow-sm overflow-hidden">
                    <div className="image-hover-effect">
                      <Image
                        src={works.image}
                        alt={works.title}
                        className="image-fit transition-transform"
                        style={{
                          width: "100%",
                          height: "300px",
                          transform: "scale(1)",
                          objectFit: "cover",
                          transition: "transform 0.5s ease",
                          cursor: "pointer" // Add this
                        }}
                        onClick={() => handleImageClick(startIndex + selectedWorks.indexOf(works))}
                      />
                      <div className="label-overlay">
                        <div className="label-content text-center p-3">
                          {works.title && (
                            <h3 className="fs-5 text-white mb-2">
                              {works.title}
                            </h3>
                          )}
                          {works.subtitle && (
                            <p className="text-white-50 mb-0">{works.subtitle}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>

          <Pagination className="mt-5 justify-content-center">
            {paginationItems}
          </Pagination>
        </Container>
      </section>
      <ImageModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        currentImage={originalWorksData[selectedImageIndex]?.image}
        onPrev={handlePrevImage}
        onNext={handleNextImage}
        showNavigation={true}
      />
    </>
  );
}

export default AppWorks;

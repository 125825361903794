import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import img1 from "../assets/images/img1.jpeg";

function AppAbout() {
  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">Learn more about who we are</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <h4>Mission Statement:</h4>
            <p>
              At East African Youth Panel, we are dedicated to cultivating a
              culture of innovation, creativity, and entrepreneurship among the
              youth of East Africa. Our mission is to empower young individuals
              to unlock their full potential, providing them with the necessary
              tools, resources, and support to excel in their chosen fields.
              Through our comprehensive programs and initiatives, we strive to
              foster a dynamic ecosystem that nurtures talent, drives economic
              growth, and spurs positive social impact. We are committed to
              recognizing and celebrating the achievements of young innovators,
              propelling them towards success and inspiring a generation of
              trailblazers.
            </p>

            <h4>Vision Statement:</h4>
            <p>
              Our vision is to be the premier platform for youth innovation and
              creativity in East Africa. We envision a future where our region's
              youth are at the forefront of change, driving transformative
              solutions and shaping the landscape of various industries. By
              creating a vibrant community of aspiring entrepreneurs and
              visionaries, we aim to build a network that fosters collaboration,
              learning, and growth. Through our prestigious awards and
              recognition programs, we aim to celebrate and amplify the
              achievements of exceptional young individuals, inspiring others to
              follow in their footsteps. Together, we will empower East African
              youth to lead, innovate, and create a brighter future for our
              region.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;
